import React from "react";

import Header from './Header'
import Footer from "./Footer";

import banner from "./../../images/banner.png";

const RegistrationIsClosed = () => {
    return (
        <div>
            <Header />
            <div className="container page-container">
                <div className="row">
                    <div className="col-md-12 fill">
                        <img src={banner} alt="" className="img-fluid" />
                    </div>
                </div>
                <div className="mt-4 mb-5">
                    Die Anmeldung zum ETH-Tag 2024 ist bereits geschlossen. Bei Fragen wenden Sie sich bitte an <a href="mailto:ethtag@ethz.ch">ethtag@ethz.ch</a>, vielen Dank!
                </div>
                <Footer language={"de"} />
            </div>
        </div>

    );
};

export default RegistrationIsClosed;