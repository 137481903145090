import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "ticketino-api-client";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";

import banner from "../images/banner.png";

const Confirmation = () => {
    const { orderId } = useParams();
    const { promocode } = useParams();

    const [tokenLoaded, setTokenLoaded] = useState(false);
    const [order, setOrder] = useState([]);
    const [organizerId, setOrganizerId] = useState(0);
    const [loading, setLoading] = useState(true);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching resources
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        // gtm - purchase
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'purchase'
        });
    }, [])

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    useEffect(() => {
        requestResources();

    }, [language, tokenLoaded]);

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            setTokenLoaded(true);
            requestFormSettings();
        });
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            setOrganizerId(res.data.organizerId);
            loadOrder(orderId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);

            setOrder(order);

            let isValid = false;

            if (order == null) navigate(`/${language}/notfound`);

            order.tickets.forEach(ticket => {

                if (ticket.promotionCode === promocode) {
                    isValid = true;
                }

                if (isValid) {
                    setLoading(false);
                } else {
                    navigate(`/${language}/notfound`);
                }
            });

        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const getReceiptOfOrder = async () => {
        await axios
            .get(`${baseUrl}/Order/${orderId}/Receipt?organizerId=${organizerId}`, {
                responseType: "blob",
            })
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Receipt.pdf";
                link.click();
            })
            .catch((error) => console.error(error.response.data));
    };

    const getPdfTicketsOfOrder = async () => {
        await axios
            .get(
                `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Tickets.pdf";
                link.click();
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <div>
            {loading &&
                <Loading bgColor="#fff" color="#eb6983" className="mt-5" />
            }
            {!loading &&
                <>
                    <Header />
                    <div className="container page-container">
                        <div className="row">
                            <div className="col-md-12 fill">
                                <img src={banner} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="mt-4 mb-5">
                            <p className="fs-2 fw-bold mb-2">{resources?.TicketDownloadTitle}</p>
                            <p dangerouslySetInnerHTML={{ __html: resources?.TicketDownloadDescription }}></p>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <button
                                className="btn-register button"
                                onClick={() => getPdfTicketsOfOrder()}
                            >
                                {resources.TicketDownload}
                            </button>
                        </div>
                    </div>
                </div>
                </>
            }
            <Footer language={language} />
        </div>
    )
}

export default Confirmation