import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const Alert = ({ buttonText, buttonClassName, dialogTitle, dialogQuestion, yesText, noText, isButtonDisabled, onAlertConfirm }) => {
    const [showModal, setShowModal] = useState(false);

    const handleDeregisterClick = () => {
        setShowModal(true);
    };

    const handleConfirmDeregister = () => {
        if (onAlertConfirm) {
            onAlertConfirm(); // Call the method passed from the parent
        }
        setShowModal(false);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <div>
            <Button onClick={handleDeregisterClick} id="btn-deregister" className={buttonClassName} disabled={isButtonDisabled}>
                {buttonText}
            </Button>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dialogTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {dialogQuestion}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {noText}
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDeregister}>
                        {yesText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Alert;