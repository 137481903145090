import React from "react";

import image from "../../images/logo.png";

const InvalidPromocode = () => {
    return (
        <div className="page-container">
            <div className="logo-div">
                <img className="logo m-3" src={image} alt="logo"></img>
            </div>
            <div className="position-absolute top-50 start-50 translate-middle">
                <div className="text-center">
                    <p className="text text-dark fw-light fs-4 mb-0">
                        Sie haben sich bereits an- oder abgemeldet. Wenn Sie Ihre Registration ändern oder sich abmelden möchten, schreiben Sie uns bitte an <a href="mailto:ethtag@ethz.ch">ethtag@ethz.ch</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default InvalidPromocode;