import axios from "axios";
import React, { useEffect, useState } from "react";

const Footer = (props) => {
    const [resources, setResources] = useState({});

    useEffect(() => {
        requestResources();
    }, [props.language]);

    const requestResources = () => {
        axios.get(`form/resources/${props.language}`).then((res) => {
            setResources(res.data);
        });
    };

    return (
        <div className="container page-container">
            <div className="row text-center">
                <p className="mt-4">
                    {resources.translation && (
                        <small style={{ color: 'grey', fontSize: '11px' }}>powered by <a style={{ color: 'grey' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                    )}
                </p>
            </div>
        </div>
    );
};

export default Footer;
