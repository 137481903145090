import React, { useEffect, useState } from 'react'
import logo from "../../images/logo.png"
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const Header = () => {
    const [resources, setResources] = useState({});

    let navigate = useNavigate();

    const { language } = useParams();

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.header);
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <>
            <div className="container mt-4 mb-5 page-container">
                <div className="row">
                    <div className="col-md-3">
                        <a href="https://ethz.ch">
                            <img src={logo} alt="" className="img-fluid" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header