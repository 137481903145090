import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getTicketTypesByEventId, getCountries } from "ticketino-api-client";
import axios from "axios";


import Header from './components/Header'
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import Alert from "./components/Alert";

import banner from "../images/banner.png";

const Home = () => {
    const [orderId, setOrderId] = useState();

    const [ticketTypeIdRegister, setTicketTypeIdRegister] = useState(0);
    const [ticketTypeIdDeregister, setTicketTypeIdDeregister] = useState(0);

    const [eventId, setEventId] = useState(0);
    const [posId, setPosId] = useState(0);
    const [organizerId, setOrganizerId] = useState(0);

    const [orderLoaded, setOrderLoaded] = useState(false);
    const [tokenLoaded, setTokenLoaded] = useState(false);
    const [isAgbChecked, setIsAgbChecked] = useState(false);

    const [promotioncode, setPromotioncode] = useState("");
    const [promotioncodeAdded, setPromotioncodeAdded] = useState("");

    const [hasCompanion, setHasCompanion] = useState(false);
    const [isDeregistration, setIsDeregistration] = useState(false);

    const [isDisableTextBox, setIsDisableTextBox] = useState(false);
    const [isDisableTextBoxTitle, setIsDisableTextBoxTitle] = useState(false);
    const [isDisableTextBoxEmail, setIsDisableTextBoxEmail] = useState(false);
    const [isDisableTextBoxFunction, setIsDisableTextBoxFunction] = useState(false);
    const [isDisableTextBoxCompany, setIsDisableTextBoxCompany] = useState(false);
    const [isDisableTextBoxTelePhoneNumber, setIsDisableTextBoxTelePhoneNumber] = useState(false);
    const [errors, setErrors] = useState("");

    const [loading, setLoading] = useState(false);

    const [buyerInfo, setBuyerInfo] = useState({
        salutation: "",
        title: "",
        firstName: "",
        name: "",
        company: "",
        function: "",
        email: "",
        street: "",
        houseNumber: "",
        mobile: "",
        city: "",
        postCode: "",
        countryId: 176,
        hasLunch: false,
        isVegetarian: false,
        isVegan: false,
        hasParking: false,
        isBarrierFree: false,
        comment: "",
        option9: "",
        option10: "",
        option11: "",
        option12: "",
        option13: "",
    });

    const [companionInfo, setCompanionInfo] = useState({
        salutationCompanion: "",
        titleCompanion: "",
        firstNameCompanion: "",
        nameCompanion: "",
        hasLunchCompanion: false,
        isVegetarianCompanion: false,
        isVeganCompanion: false,
    });

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});
    const [countries, setCountries] = useState([]);

    // fetching params
    const { language, code } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        loadToken();

        // clear the addedPromocodes in the session
        let array = [];
        sessionStorage.setItem("AddedPromotions", JSON.stringify(array));

    }, []); //only on first page load

    useEffect(() => {
        requestResources();

        // so it wont get called on first load
        if (orderLoaded) {
            startOrder(posId, eventId);
        }
        if (tokenLoaded) {
            loadCountries();
        }

    }, [language, tokenLoaded]); //everytime language is changed

    useEffect(() => {
        if (code && tokenLoaded && organizerId && organizerId !== 0 && orderId && orderId !== 0) {
            applyPromotionCodeToOrder(orderId, code);

            if (code !== "ethtag2024") {
                setLoading(true);
                loadAddress(orderId, code);
            }
        }
    }, [code, tokenLoaded, orderId, organizerId]);

    useEffect(() => {
        if (!buyerInfo.hasLunch) {
            setBuyerInfo((prevBuyerInfo) => ({
                ...prevBuyerInfo,
                isVegetarian: false,
                isVegan: false
            }));

            setCompanionInfo((prevCompanionInfo) => ({
                ...prevCompanionInfo,
                hasLunchCompanion: false,
                isVegetarianCompanion: false,
                isVeganCompanion: false
            }));
        }
    }, [buyerInfo.hasLunch]);

    useEffect(() => {
        if (!companionInfo.hasLunchCompanion) {
            setCompanionInfo((prevCompanionInfo) => ({
                ...prevCompanionInfo,
                isVegetarianCompanion: false,
                isVeganCompanion: false
            }));
        }
    }, [companionInfo.hasLunchCompanion]);

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            setTokenLoaded(true);
            requestFormSettings();
        });
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            // setting the posID and eventID etc
            setOrganizerId(res.data.organizerId);
            setPosId(res.data.posId);
            setEventId(res.data.eventId);
            setTicketTypeIdRegister(res.data.registrationTicketTypeId);
            setTicketTypeIdDeregister(res.data.deregistrationTicketTypeId);
            startOrder(res.data.posId, res.data.eventId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadCountries = async () => {
        try {
            let countries = await getCountries(language);

            const indexCH = countries.findIndex(c => c.id === 176);
            const ch = countries[indexCH];
            countries.splice(indexCH, 1);

            var indexDE = countries.findIndex(c => c.id === 229);
            const de = countries[indexDE];
            countries.splice(indexDE, 1);

            var indexAU = countries.findIndex(c => c.id === 228);
            const au = countries[indexAU];
            countries.splice(indexAU, 1);

            countries.unshift({ id: 0, name: "---------" });
            countries.unshift(au);
            countries.unshift(de);
            countries.unshift(ch);

            setCountries(countries);
        }
        catch (error) {
            console.error(error);
        }
    }

    const startOrder = async (posId, eventId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 10,
        };

        await axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("orderId", res.data.id);
                setOrderId(res.data.id);
                setOrderLoaded(true);
            })
            .catch((error) => console.error(error.response.data));
    };

    const applyPromotionCodeToOrder = async (orderId, code) => {
        try {
            const response = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            if (error.response.status < 200 || error.response.status >= 300) {
                navigate(`/${language}/InvalidPromocode`);
            }
        }
    }

    const getAddressOfPromotionCode = async (promotionCode, organizerId) => {
        try {
            const res = await axios.get(`${baseUrl}/PromotionCode/${promotionCode}/Address?organizerId=${organizerId}`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    }

    const loadAddress = async (orderId, code) => {
        try {
            let updatedAddress = {};

            try {
                updatedAddress = await getAddressOfPromotionCode(code, organizerId);
            } catch { }

            if (updatedAddress) {
                setBuyerInfo((prevBuyerInfo) => ({
                    ...prevBuyerInfo,
                    salutation: updatedAddress.salutation,
                    title: updatedAddress.title,
                    firstName: updatedAddress.firstName,
                    name: updatedAddress.name,
                    company: updatedAddress.company,
                    function: updatedAddress.function,
                    email: updatedAddress.email,
                    mobile: updatedAddress.mobile,
                    street: updatedAddress.street,
                    city: updatedAddress.city,
                    postCode: updatedAddress.postCode,
                    houseNumber: updatedAddress.code1,
                    countryId: updatedAddress.countryId,
                    code9: updatedAddress.code9,
                    code10: updatedAddress.code10,
                    code11: updatedAddress.code11,
                    code12: updatedAddress.code12,
                    code13: updatedAddress.code13,
                }));

                setIsDisableTextBox(true);

                if (updatedAddress.email !== "" && updatedAddress.email !== null) {
                    setIsDisableTextBoxEmail(true);
                }
                if (updatedAddress.title !== "" && updatedAddress.title !== null) {
                    setIsDisableTextBoxTitle(true);
                }
                if (updatedAddress.function !== "" && updatedAddress.function !== null) {
                    setIsDisableTextBoxFunction(true);
                }
                if (updatedAddress.company !== "" && updatedAddress.company !== null) {
                    setIsDisableTextBoxCompany(true);
                }
                if (updatedAddress.mobile !== "" && updatedAddress.mobile !== null) {
                    setIsDisableTextBoxTelePhoneNumber(true);
                }
            }

            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const addTicketToBasket = async (ticketTypeId) => {

        let addTicketTypes = [];

        let countryId = Number(buyerInfo.countryId); // Convert to number if necessary
        let country = countries.find(c => c.id === countryId);

        addTicketTypes.push({
            ticketTypeId: ticketTypeId,
            quantity: 1,
            salutation: buyerInfo.salutation,
            title: buyerInfo.title,
            firstname: buyerInfo.firstName,
            lastname: buyerInfo.name,
            company: buyerInfo.company,
            Function: buyerInfo.function,
            email: buyerInfo.email,
            mobile: buyerInfo.mobile,
            street: buyerInfo.street,
            postCode: buyerInfo.postCode,
            city: buyerInfo.city,
            country: country.name,
            option1: "house number: " + buyerInfo.houseNumber,
            option2: "Stehlunch: " + buyerInfo.hasLunch,
            option3: "Vegetarisch: " + buyerInfo.isVegetarian,
            option4: "Vegan: " + buyerInfo.isVegan,
            option5: "Parkplatz: " + buyerInfo.hasParking,
            option6: "barrierefreien: " + buyerInfo.isBarrierFree,
            option7: "Kommentar: " + buyerInfo.comment,
            option9: buyerInfo.code9,
            option10: buyerInfo.code10,
            option11: buyerInfo.code11,
            option12: buyerInfo.code12,
            option13: buyerInfo.code13,
            promotionCode: code
        });

        if (hasCompanion) {
            addTicketTypes.push({
                ticketTypeId: ticketTypeId,
                quantity: 1,
                salutation: companionInfo.salutationCompanion,
                title: companionInfo.titleCompanion,
                firstname: companionInfo.firstNameCompanion,
                lastname: companionInfo.nameCompanion,
                option2: "Stehlunch: " + companionInfo.hasLunchCompanion,
                option3: "Vegetarisch: " + companionInfo.isVegetarianCompanion,
                option4: "Vegan: " + companionInfo.isVeganCompanion,
                option8: "Companion: True",
                promotionCode: code
            });
        }

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                // gtm - addToCart
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'addToCart'
                });
                addAddressToBasket();
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const addAddressToBasket = async () => {
        let addressBody = { ...buyerInfo };

        await axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then(() => {
                // gtm - addAddress
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'addAddress'
                });
                finishFreeOrder();
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const finishFreeOrder = async () => {
        await axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PaymentType/5`)
            .then(() => {
                confirmShopbasketByOrderId(orderId);
                navigate(`/${language}/confirmation/${orderId}`);
            });
    };

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
        } catch (error) {
            console.error(error)
        }
    };

    const onSubmit = async () => {

        if (validateEntries()) {

            sessionStorage.setItem("IsRegeristration", false);
            await addTicketToBasket(ticketTypeIdRegister);
        }
    };

    const onDeregisterConfirm = async () => {
        if (validateEntries()) {
            sessionStorage.setItem("IsRegeristration", true);
            await addTicketToBasket(ticketTypeIdDeregister);
        }
    };

    const onInputChangeHasCompanion = (e) => {
        setHasCompanion(e.target.checked);
    };

    const onInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setBuyerInfo((prevBuyerInfo) => {
            if (name === 'isVegetarian' && checked) {
                return { ...prevBuyerInfo, isVegetarian: true, isVegan: false };
            } else if (name === 'isVegan' && checked) {
                return { ...prevBuyerInfo, isVegetarian: false, isVegan: true };
            }
            return { ...prevBuyerInfo, [name]: type === 'checkbox' ? checked : value };
        });
    };

    const onInputChangeCompanion = (e) => {
        const { name, value, type, checked } = e.target;
        setCompanionInfo((prevCompanionInfo) => {
            if (name === 'isVegetarianCompanion' && checked) {
                return { ...prevCompanionInfo, isVegetarianCompanion: true, isVeganCompanion: false };
            } else if (name === 'isVeganCompanion' && checked) {
                return { ...prevCompanionInfo, isVegetarianCompanion: false, isVeganCompanion: true };
            }
            return { ...prevCompanionInfo, [name]: type === 'checkbox' ? checked : value };
        });
    };

    const onAgbChange = (isAgbChecked) => {
        setIsAgbChecked(isAgbChecked);
    };

    const validateEntries = () => {

        if (!(buyerInfo.firstName && /^.{1,70}$/.test(buyerInfo.firstName)) ||
            !(buyerInfo.name && /^.{1,70}$/.test(buyerInfo.name)) ||
            !(buyerInfo.company && /^.{1,70}$/.test(buyerInfo.company)) ||
            !(buyerInfo.function && /^.{1,70}$/.test(buyerInfo.function)) ||
            !(buyerInfo.street && /^.{1,70}$/.test(buyerInfo.street)) ||
            !(buyerInfo.postCode && /^.{1,70}$/.test(buyerInfo.postCode)) ||
            !(buyerInfo.city && /^.{1,70}$/.test(buyerInfo.city)) ||
            !(isAgbChecked) ||
            !(buyerInfo.countryId > 0)) {
            setErrors(resources?.FillMandatoryFields);
            return false;
        }
        else if (!(buyerInfo.email && /^\S+@\S+\.\S+$/.test(buyerInfo.email))) {
            setErrors(resources?.EmailNotCorrect);
            return false;
        }
        else {
            if (hasCompanion) {
                let validationCompanion =
                    companionInfo.firstNameCompanion && /^.{1,70}$/.test(companionInfo.firstNameCompanion) &&
                    companionInfo.nameCompanion && /^.{1,70}$/.test(companionInfo.nameCompanion);

                if (!validationCompanion) {
                    setErrors(resources?.FillMandatoryFields);
                    return false;
                }
            }
        }
        setErrors("");
        return true;
    }

    //let validation = false;

    //let validationMain =
    //    buyerInfo.firstName && /^.{1,70}$/.test(buyerInfo.firstName) &&
    //    buyerInfo.name && /^.{1,70}$/.test(buyerInfo.name) &&
    //    buyerInfo.company && /^.{1,70}$/.test(buyerInfo.company) &&
    //    buyerInfo.function && /^.{1,70}$/.test(buyerInfo.function) &&
    //    buyerInfo.email && /^\S+@\S+\.\S+$/.test(buyerInfo.email) &&
    //    buyerInfo.street && /^.{1,70}$/.test(buyerInfo.street) &&
    //    //buyerInfo.houseNumber && /^.{1,70}$/.test(buyerInfo.houseNumber) &&
    //    buyerInfo.postCode && /^.{1,70}$/.test(buyerInfo.postCode) &&
    //    buyerInfo.city && /^.{1,70}$/.test(buyerInfo.city) &&
    //    buyerInfo.countryId > 0;

    //let validationCompanion = true;

    //if (hasCompanion) {
    //    validationCompanion =
    //        companionInfo.firstNameCompanion && /^.{1,70}$/.test(companionInfo.firstNameCompanion) &&
    //        companionInfo.nameCompanion && /^.{1,70}$/.test(companionInfo.nameCompanion);
    //}

    //if (isAgbChecked) validation = true;

    return (
        <div>
            <Header />
            {loading ? (
                <>
                    <Loading
                        alignment="center"
                        color="#d3d3d3"
                        bgColor="#fff"
                        top="50%"
                    />

                </>
            ) : (
                <>
                    <div className="container page-container">
                        <div className="row">
                            <div className="col-md-12 fill">
                                <img src={banner} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="mt-4 mb-5">
                            <p className="fs-2 fw-bold mb-2">{resources?.HomeTitle}</p>
                            <p dangerouslySetInnerHTML={{ __html: resources?.HomeTitleDescription }}></p>
                        </div>

                        <div className="mt-4 page-container-smaller">
                            {/* start main participant */}
                            <div className="mb-3 row">
                                <label className="col-md-3 col-12 mb-2" htmlFor="salutation">
                                    {resources?.BuyerSalutation}:
                                </label>
                                <div className="col-sm-9 col-md-9 col-12">
                                    <select
                                        className="form-select"
                                        aria-label=""
                                        id="salutation"
                                        name="salutation"
                                        value={buyerInfo.salutation}
                                        disabled={isDisableTextBox}
                                        onChange={onInputChange}
                                    >
                                        <option value="">{resources?._Choose}</option>
                                        <option value={resources?.Mr}>{resources?.Mr}</option>
                                        <option value={resources?.Ms}>{resources?.Ms}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-3 col-12 mb-2" htmlFor="title">
                                    {resources?.BuyerTitle}:
                                </label>
                                <div className="col-sm-9 col-md-9 col-12">
                                    <input
                                        type="text"
                                        name="title"
                                        className="form-control"
                                        placeholder={resources?.BuyerTitle}
                                        value={buyerInfo.title}
                                        id="title"
                                        disabled={isDisableTextBoxTitle}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-3 col-12 mb-2" htmlFor="firstName">
                                    {resources?.BuyerFirstname}: <span className="mandatory">*</span>
                                </label>
                                <div className="col-sm-9 col-md-9 col-12">
                                    <input
                                        type="text"
                                        name="firstName"
                                        className="form-control"
                                        placeholder={resources?.BuyerFirstname}
                                        value={buyerInfo.firstName}
                                        id="firstName"
                                        disabled={isDisableTextBox}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-3 col-12 mb-2" htmlFor="name">
                                    {resources?.BuyerLastname}: <span className="mandatory">*</span>
                                </label>
                                <div className="col-sm-9 col-md-9 col-12">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder={resources?.BuyerLastname}
                                        value={buyerInfo.name}
                                        id="name"
                                        disabled={isDisableTextBox}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-3 col-12 mb-2" htmlFor="company">
                                    {resources?.BuyerCompany}: <span className="mandatory">*</span>
                                </label>
                                <div className="col-sm-9 col-md-9 col-12">
                                    <input
                                        type="text"
                                        name="company"
                                        className="form-control"
                                        placeholder={resources?.BuyerCompany}
                                        value={buyerInfo.company}
                                        id="company"
                                        disabled={isDisableTextBoxCompany}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-3 col-12 mb-2" htmlFor="function">
                                    {resources?.BuyerFunction}: <span className="mandatory">*</span>
                                </label>
                                <div className="col-sm-9 col-md-9 col-12">
                                    <input
                                        type="text"
                                        name="function"
                                        className="form-control"
                                        placeholder={resources?.BuyerFunction}
                                        value={buyerInfo.function}
                                        id="function"
                                        disabled={isDisableTextBoxFunction}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-3 col-12 mb-2" htmlFor="email">
                                    {resources?.BuyerEmail}: <span className="mandatory">*</span>
                                </label>
                                <div className="col-sm-9 col-md-9 col-12">
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control"
                                        placeholder={resources?.BuyerEmail}
                                        value={buyerInfo.email}
                                        id="email"
                                        disabled={false}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-3 col-12 mb-2" htmlFor="street">
                                    {resources?.BuyerNumber}:
                                </label>
                                <div className="col-sm-9 col-md-9 col-12">
                                    <input
                                        type="text"
                                        name="mobile"
                                        className="form-control"
                                        placeholder={resources?.BuyerNumber}
                                        value={buyerInfo.mobile}
                                        id="mobile"
                                        disabled={isDisableTextBoxTelePhoneNumber}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-3 col-12 mb-2" htmlFor="street">
                                    {resources?.BuyerStreet}: <span className="mandatory">*</span>
                                </label>
                                <div className="col-sm-9 col-md-9 col-12">
                                    <input
                                        type="text"
                                        name="street"
                                        className="form-control"
                                        placeholder={resources?.BuyerStreet}
                                        value={buyerInfo.street}
                                        id="street"
                                        disabled={isDisableTextBox}
                                        onChange={onInputChange}
                                    />
                                    {/*<div className="col-md-4 col-4 pe-0">*/}
                                    {/*    <input*/}
                                    {/*        type="text"*/}
                                    {/*        name="houseNumber"*/}
                                    {/*        className="form-control"*/}
                                    {/*        placeholder={resources?.BuyerHouseNumber}*/}
                                    {/*        value={buyerInfo.houseNumber}*/}
                                    {/*        id="houseNumber"*/}
                                    {/*        disabled={isDisableTextBox}*/}
                                    {/*        onChange={onInputChange}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-3 col-12 mb-2" htmlFor="postCode">
                                    {resources?.BuyerPostcode} / {resources?.BuyerCity}: <span className="mandatory">*</span>
                                </label>
                                <div className="col-md-9 col-12 row pe-0">
                                    <div className="col-md-4 col-4 pe-0">
                                        <input
                                            type="text"
                                            name="postCode"
                                            className="form-control"
                                            placeholder={resources?.BuyerPostcode}
                                            value={buyerInfo.postCode}
                                            id="postCode"
                                            disabled={isDisableTextBox}
                                            onChange={onInputChange}
                                        />
                                    </div>
                                    <div className="col-md-8 col-8 pe-0">
                                        <input
                                            type="text"
                                            name="city"
                                            className="form-control"
                                            placeholder={resources?.BuyerCity}
                                            value={buyerInfo.city}
                                            id="city"
                                            disabled={isDisableTextBox}
                                            onChange={onInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-3 col-12 mb-2" htmlFor="country">
                                    {resources?.BuyerCountry}: <span className="mandatory">*</span>
                                </label>
                                <div className="col-sm-9 col-md-9 col-12">
                                    <select
                                        className="form-select"
                                        aria-label=""
                                        id="countryId"
                                        name="countryId"
                                        value={buyerInfo.countryId}
                                        disabled={isDisableTextBox}
                                        onChange={onInputChange}
                                    >
                                        {
                                            countries.map((country, index) => (
                                                <option key={country.id} value={country.id}>{country.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <hr />
                            <div className="form-check">
                                <input
                                    id="chkHasLunch"
                                    type="checkbox"
                                    className="form-check-input"
                                    name="hasLunch"
                                    checked={buyerInfo.hasLunch}
                                    onChange={onInputChange}
                                />
                                <label className="form-check-label" htmlFor="chkHasLunch">{resources?.BuyerHasLunch}</label>
                            </div>
                            <div className="d-inline-block me-4">
                                <label>{resources?.BuyerHasLunchDesc}</label>:
                            </div>
                            <div className="form-check d-inline-block me-4">
                                <input
                                    id="chkIsVegetarian"
                                    type="checkbox"
                                    className="form-check-input"
                                    name="isVegetarian"
                                    checked={buyerInfo.isVegetarian && buyerInfo.hasLunch}
                                    onChange={onInputChange}
                                    disabled={!buyerInfo.hasLunch}
                                />
                                <label className="form-check-label" htmlFor="chkIsVegetarian">{resources?.BuyerHasLunchVegetarian}</label>

                            </div>
                            <div className="form-check d-inline-block">
                                <input
                                    id="chkIsVegan"
                                    type="checkbox"
                                    className="form-check-input"
                                    name="isVegan"
                                    checked={buyerInfo.isVegan && buyerInfo.hasLunch}
                                    onChange={onInputChange}
                                    disabled={!buyerInfo.hasLunch}
                                />
                                <label className="form-check-label" htmlFor="chkIsVegan">{resources?.BuyerHasLunchVegan}</label>
                            </div>
                            <div className="mt-4 form-check">
                                <input
                                    id="chkHasParking"
                                    type="checkbox"
                                    className="form-check-input"
                                    name="hasParking"
                                    checked={buyerInfo.hasParking}
                                    onChange={onInputChange}
                                />
                                <label className="form-check-label" htmlFor="chkHasParking">{resources?.BuyerHasParking}</label>
                            </div>
                            <div className="mt-4 form-check">
                                <input
                                    id="chkIsBarrierFree"
                                    type="checkbox"
                                    className="form-check-input"
                                    name="isBarrierFree"
                                    checked={buyerInfo.isBarrierFree}
                                    onChange={onInputChange}
                                />
                                <label className="form-check-label" htmlFor="chkIsBarrierFree">{resources?.BuyerIsBarrierFree}</label>
                            </div>
                            <div className="mt-4 mb-3 row">
                                <label className="col-md-3 col-12 mb-2" htmlFor="comment">
                                    {resources?.HomeComment}:
                                </label>
                                <div className="col-sm-9 col-md-9 col-12">
                                    <textarea
                                        type="textfield"
                                        name="comment"
                                        className="form-control"
                                        value={buyerInfo.comment}
                                        id="comment"
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            {/* end main participant */}
                            <hr />
                            {/* start companion */}
                            <div className="mt-4 mb-4 form-check">
                                <input
                                    id="chkHasCompanion"
                                    type="checkbox"
                                    className="form-check-input"
                                    name="hasCompanion"
                                    checked={hasCompanion}
                                    onChange={onInputChangeHasCompanion}
                                />
                                <label className="form-check-label fw-bold" htmlFor="chkHasCompanion">{resources?.BuyerHasCompanion}</label>
                            </div>
                            {hasCompanion &&
                                <>
                                    <div className="mb-3 row">
                                        <label className="col-md-3 col-12 mb-2" htmlFor="salutationCompanion">
                                            {resources?.BuyerSalutation}:
                                        </label>
                                        <div className="col-sm-9 col-md-9 col-12">
                                            <select
                                                className="form-select"
                                                aria-label=""
                                                id="salutationCompanion"
                                                name="salutationCompanion"
                                                value={companionInfo.salutationCompanion}
                                                onChange={onInputChangeCompanion}
                                            >
                                                <option value="">{resources?._Choose}</option>
                                                <option value={resources?.Mr}>{resources?.Mr}</option>
                                                <option value={resources?.Ms}>{resources?.Ms}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label className="col-md-3 col-12 mb-2" htmlFor="titleCompanion">
                                            {resources?.BuyerTitle}:
                                        </label>
                                        <div className="col-sm-9 col-md-9 col-12">
                                            <input
                                                type="text"
                                                name="titleCompanion"
                                                className="form-control"
                                                placeholder={resources?.BuyerTitle}
                                                value={companionInfo.titleCompanion}
                                                id="titleCompanion"
                                                onChange={onInputChangeCompanion}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label className="col-md-3 col-12 mb-2" htmlFor="firstNameCompanion">
                                            {resources?.BuyerFirstname}: <span className="mandatory">*</span>
                                        </label>
                                        <div className="col-sm-9 col-md-9 col-12">
                                            <input
                                                type="text"
                                                name="firstNameCompanion"
                                                className="form-control"
                                                placeholder={resources?.BuyerFirstname}
                                                value={companionInfo.firstNameCompanion}
                                                id="firstNameCompanion"
                                                onChange={onInputChangeCompanion}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4 row">
                                        <label className="col-md-3 col-12 mb-2" htmlFor="nameCompanion">
                                            {resources?.BuyerLastname}: <span className="mandatory">*</span>
                                        </label>
                                        <div className="col-sm-9 col-md-9 col-12">
                                            <input
                                                type="text"
                                                name="nameCompanion"
                                                className="form-control"
                                                placeholder={resources?.BuyerLastname}
                                                value={companionInfo.nameCompanion}
                                                id="nameCompanion"
                                                onChange={onInputChangeCompanion}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            id="chkHasLunchCompanion"
                                            type="checkbox"
                                            className="form-check-input"
                                            name="hasLunchCompanion"
                                            checked={companionInfo.hasLunchCompanion}
                                            disabled={!buyerInfo.hasLunch}
                                            onChange={onInputChangeCompanion}
                                        />
                                        <label className="form-check-label" htmlFor="chkHasLunchCompanion">{resources?.BuyerHasLunch}</label>
                                    </div>
                                    <div className="d-inline-block me-4">
                                        <label>{resources?.BuyerHasLunchDesc}</label>:
                                    </div>
                                    <div className="form-check d-inline-block me-4">
                                        <input
                                            id="chkIsVegetarianCompanion"
                                            type="checkbox"
                                            className="form-check-input"
                                            name="isVegetarianCompanion"
                                            checked={companionInfo.isVegetarianCompanion && buyerInfo.hasLunch && companionInfo.hasLunchCompanion}
                                            onChange={onInputChangeCompanion}
                                            disabled={!(buyerInfo.hasLunch && companionInfo.hasLunchCompanion)}
                                        />
                                        <label className="form-check-label" htmlFor="chkIsVegetarianCompanion">{resources?.BuyerHasLunchVegetarian}</label>
                                    </div>
                                    <div className="form-check d-inline-block">
                                        <input
                                            id="chkIsVeganCompanion"
                                            type="checkbox"
                                            className="form-check-input"
                                            name="isVeganCompanion"
                                            checked={companionInfo.isVeganCompanion && buyerInfo.hasLunch && companionInfo.hasLunchCompanion}
                                            onChange={onInputChangeCompanion}
                                            disabled={!(buyerInfo.hasLunch && companionInfo.hasLunchCompanion)}
                                        />
                                        <label className="form-check-label" htmlFor="chkIsVeganCompanion">{resources?.BuyerHasLunchVegan}</label>
                                    </div>
                                </>
                            }
                            {/* end companion */}
                            <hr />

                            <div className="form-check">
                                <input id="chkAgb" type="checkbox" className="form-check-input" checked={isAgbChecked} onChange={() => onAgbChange(!isAgbChecked)} />
                                <label className="form-check-label d-inline" htmlFor="chkAgb" dangerouslySetInnerHTML={{ __html: resources?.AGB }}></label> <span className="mandatory d-inline">*</span>
                            </div>

                            <div className="row mt-4 ">
                                <div className="col-6">
                                    <Alert
                                        buttonText={resources?._Deregister}
                                        buttonClassName="btn-deregister button btn"
                                        dialogTitle={resources?._ConfirmDeregistration}
                                        dialogQuestion={resources?._ConfirmDeregistrationQuestion}
                                        yesText="Ja"
                                        noText="Nein"
                                        onAlertConfirm={onDeregisterConfirm}
                                    />
                                </div>

                                <div className="col-6 text-end">
                                    <button className="btn-register button" onClick={() => onSubmit()}>
                                        {resources?._Register}
                                    </button>
                                </div>
                            </div>

                            {errors !== "" && (
                                <div className="row text-start mt-4">
                                    <div cassName="offset-md-6 col-md-6"></div>
                                    <p className="text-danger ps-2">*{errors}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <Footer language={language} />
                </>
            )};
        </div>
    )
}

export default Home